<template>
  <b-overlay
    :show="loading"
    rounded="sm"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
  >
    <b-card class="product-add-wrapper">
      <validation-observer ref="addProductValidation">
        <!-- form -->
        <b-form>
          <b-row>
            <!-- Title -->
            <b-col md="10">
              <b-form-group
                label="Title"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Title"
                  rules="required"
                >
                  <b-form-input
                    v-model="title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Active -->
            <b-col
              md="2"
            >
              <b-form-group
                style="margin-top: 2rem"
              >
                <b-form-checkbox
                  v-model="isActive"
                  name="check-button"
                  switch
                  inline
                >
                  Active
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <!-- Key -->
            <b-col md="6">
              <b-form-group
                label="Key"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Key"
                  rules="required"
                >
                  <b-form-input
                    v-model="key"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ConfigUrl -->
            <b-col md="6">
              <b-form-group
                label="Config Url"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Config Url"
                  rules="required"
                >
                  <b-form-input
                    v-model="configUrl"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Cukcuk Categories -->
            <b-col md="6">
              <b-form-group
                label="Cukcuk Categories"
                label-for="program-add-title"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Cukcuk Categories"
                  rules="required"
                >
                  <v-select
                    id="program-add-category"
                    v-model="cukcukCategories"
                    multiple
                    :options="cukcukCategoryOptions"
                    :clearable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Cukcuk Categories -->
            <b-col md="6">
              <b-form-group
                label="Cukcuk Branch"
                label-for="program-add-title"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Cukcuk Branch"
                  rules="required"
                >
                  <v-select
                    id="program-add-category"
                    v-model="cukcukBranch"
                    :options="cukcukBranchOptions"
                    :clearable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="12"
            >
              <label for="description-form">Options for translate each program category</label>
              <transition-group
                id="description-form"
                name="custom-classes"
                enter-active-class="animate__animated animate__fadeInDown"
                leave-active-class="animate__animated animate__fadeOutUp"
                :duration="300"
              >
                <b-card-body
                  v-for="index in countNoteGeneral"
                  :key="index"
                >
                  <!-- repeater from -->
                  <b-col cols="12">
                    <div class="border rounded p-2">
                      <div
                        class="d-flex flex-row-reverse py-50 px-25 col-sm-12"
                      >
                        <feather-icon
                          v-if="arrayNoteGeneral.length > 1"
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeItemDescription(index - 1)"
                        />
                      </div>
                      <b-row>
                        <!-- Language code -->
                        <b-col md="12">
                          <b-form-group
                            label="Language"
                            label-for="product-add-title"
                            class="mb-2"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="Language"
                              rules="required"
                            >
                              <v-select
                                id="product-add-category"
                                v-model="arrayNoteGeneral[index - 1].languageCode"
                                :options="lanCodeOptions"
                                item-disabled="disable"
                                :clearable="false"
                                :selectable="(option) => {
                                  return !arrayNoteGeneral.find(x => x.languageCode ? (x.languageCode.value === option.value) : null)
                                }"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- preCheckinDes -->
                        <b-col md="12">
                          <b-form-group
                            label="Pre Checkin Des"
                            class="mb-2"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="Pre Checkin Des"
                              rules="required"
                            >
                              <quill-editor
                                v-model="arrayNoteGeneral[index - 1].preCheckinDes"
                                class="editor-form"
                                :options="snowOption"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- preCheckinPrivacy -->
                        <b-col md="6">
                          <b-form-group
                            label="Pre Checkin Privacy"
                            class="mb-2"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="Pre Checkin Privacy"
                              rules="required"
                            >
                              <quill-editor
                                v-model="arrayNoteGeneral[index - 1].preCheckinPrivacy"
                                class="editor-form"
                                :options="snowOption"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- checkinHotelNote -->
                        <b-col md="6">
                          <b-form-group
                            label="Checkin Hotel Note"
                            class="mb-2"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="Checkin Hotel Note"
                              rules="required"
                            >
                              <quill-editor
                                v-model="arrayNoteGeneral[index - 1].checkinHotelNote"
                                class="editor-form"
                                :options="snowOption"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- checkOutHotelNote -->
                        <b-col md="6">
                          <b-form-group
                            label="Checkout Hotel Note"
                            class="mb-2"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="Checkout Hotel Note"
                              rules="required"
                            >
                              <quill-editor
                                v-model="arrayNoteGeneral[index - 1].checkoutHotelNote"
                                class="editor-form"
                                :options="snowOption"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- checkOutHotelNote -->
                        <b-col md="6">
                          <b-form-group
                            label="Pre Checkout Hotel Note"
                            class="mb-2"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="Pre Checkout Hotel Note"
                              rules="required"
                            >
                              <quill-editor
                                v-model="arrayNoteGeneral[index - 1].preCheckoutHotelNote"
                                class="editor-form"
                                :options="snowOption"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- availableCancel
                        <b-col md="6">
                          <b-form-group
                            label="Available Cancel"
                            class="mb-2"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="Available Cancel"
                              rules="required"
                            >
                              <quill-editor
                                v-model="arrayNoteGeneral[index - 1].availableCancel"
                                class="editor-form"
                                :options="snowOption"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col> -->
                      </b-row>
                      <b-form-group
                        v-slot="{ ariaDescribedby }"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Default"
                          rules="required"
                        >
                          <b-form-radio
                            v-model="numberDefault"
                            :aria-describedby="ariaDescribedby"
                            name="some-radios"
                            :value="index - 1"
                          >
                            Default language
                          </b-form-radio>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </b-col>
                </b-card-body>
              </transition-group>
              <b-col
                cols="12"
                class="pb-2"
              >
                <b-button
                  v-if="lanCodeOptions.length > arrayNoteGeneral.length"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="ml-1"
                  size="sm"
                  variant="primary"
                  @click="addNewItemInItemFormDescription"
                >
                  Add note general config in another language
                </b-button>
              </b-col>
            </b-col>
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click="updateGeneralConfig"
              >
                Update
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :to="{ name: 'general-config' }"
                variant="outline-secondary"
              >
                Cancel
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      <!--/ form -->
      </validation-observer>
    </b-card>
  </b-overlay>
</template>

<script>
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BCardBody,
  BFormRadio,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardText,
  BFormFile,
  BImg,
  BOverlay,
  BFormCheckbox,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
// import { QuillEditor } from '@vueup/vue-quill'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import router from '@/router'
import updateGeneralConfig from './updateGeneralConfig'

export default {
  components: {
    vSelect,
    BFormRadio,
    BCardBody,
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    quillEditor,
    ValidationProvider,
    ValidationObserver,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardText,
    BFormFile,
    BImg,
    BOverlay,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      snowOption: {
        placeholder: '',
        theme: 'snow',
      },
      required,
    }
  },
  computed: {
    ...mapGetters('app', ['lanCodeOptions', 'statusOptions']),
  },
  setup() {
    const {
      arrayNoteGeneral,
      countNoteGeneral,
      isActive,
      title,
      key,
      cukcukCategories,
      cukcukBranch,
      cukcukCategoryOptions,
      cukcukBranchOptions,
      loading,
      numberDefault,
      configUrl,
    } = updateGeneralConfig()
    return {
      arrayNoteGeneral,
      countNoteGeneral,
      isActive,
      title,
      key,
      cukcukCategories,
      cukcukBranch,
      cukcukCategoryOptions,
      cukcukBranchOptions,
      loading,
      numberDefault,
      configUrl,
    }
  },
  methods: {
    addNewItemInItemFormDescription() {
      this.arrayNoteGeneral.push({
        languageCode: null,
        preCheckinDes: '',
        preCheckinPrivacy: '',
        checkinHotelNote: '',
        checkoutHotelNote: '',
        preCheckoutHotelNote: '',
        isDefaultLanguage: false,
      })
      this.countNoteGeneral += 1
    },
    removeItemDescription(index) {
      this.arrayNoteGeneral.splice(index, 1)
      this.countNoteGeneral -= 1
    },
    updateGeneralConfig() {
      this.$refs.addProductValidation.validate().then(success => {
        if (success) {
          let arrGeneralPayload = []
          let programDefault = null
          if (this.arrayNoteGeneral.length) {
            programDefault = { ...this.arrayNoteGeneral[this.numberDefault] }
            programDefault.isDefaultLanguage = true
            programDefault.languageCode = programDefault.languageCode.value
            arrGeneralPayload = [...this.arrayNoteGeneral]
            arrGeneralPayload.splice(this.numberDefault, 1)
          }

          const payload = {
            title: this.title,
            isActive: this.isActive,
            key: this.key,
            configUrl: this.configUrl,
            cukcukCategories: this.cukcukCategories.map(x => x.value),
            cukcukBranch: this.cukcukBranch.value,
            translations: arrGeneralPayload.length ? arrGeneralPayload.map(x => ({
              preCheckinDes: x.preCheckinDes,
              preCheckinPrivacy: x.preCheckinPrivacy,
              checkinHotelNote: x.checkinHotelNote,
              checkoutHotelNote: x.checkoutHotelNote,
              preCheckoutHotelNote: x.preCheckoutHotelNote,
              languageCode: x.languageCode.value,
              isDefaultLanguage: false,
            })) : [],
          }
          if (programDefault) {
            payload.translations.push(programDefault)
          }
          store.dispatch('generalConfig/updateGeneralConfig', { id: this.$route.params.id, object: payload }).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Update general config successfuly',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
            // router.push('/post-category')
          }).catch(e => {
            console.log(e)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: e.response.data.error,
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css";
// @import '@core/scss/vue/libs/swiper.scss';
@import '~swiper/css/swiper.css';
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}
.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}
.slide-fade-enter-from {
  transform: translateY(20px);
  opacity: 0;
}
.slide-fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}
.ql-container {
  min-height: inherit;
}
.vs__dropdown-option--disabled {
    display: none;
}
</style>
