var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"sm","spinner-variant":"primary","spinner-type":"grow","spinner-small":""}},[_c('b-card',{staticClass:"product-add-wrapper"},[_c('validation-observer',{ref:"addProductValidation"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"10"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Title"}},[_c('validation-provider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{staticStyle:{"margin-top":"2rem"}},[_c('b-form-checkbox',{attrs:{"name":"check-button","switch":"","inline":""},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_vm._v(" Active ")])],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Key"}},[_c('validation-provider',{attrs:{"name":"Key","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.key),callback:function ($$v) {_vm.key=$$v},expression:"key"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Config Url"}},[_c('validation-provider',{attrs:{"name":"Config Url","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.configUrl),callback:function ($$v) {_vm.configUrl=$$v},expression:"configUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Cukcuk Categories","label-for":"program-add-title"}},[_c('validation-provider',{attrs:{"name":"Cukcuk Categories","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"program-add-category","multiple":"","options":_vm.cukcukCategoryOptions,"clearable":false},model:{value:(_vm.cukcukCategories),callback:function ($$v) {_vm.cukcukCategories=$$v},expression:"cukcukCategories"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Cukcuk Branch","label-for":"program-add-title"}},[_c('validation-provider',{attrs:{"name":"Cukcuk Branch","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"program-add-category","options":_vm.cukcukBranchOptions,"clearable":false},model:{value:(_vm.cukcukBranch),callback:function ($$v) {_vm.cukcukBranch=$$v},expression:"cukcukBranch"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('label',{attrs:{"for":"description-form"}},[_vm._v("Options for translate each program category")]),_c('transition-group',{attrs:{"id":"description-form","name":"custom-classes","enter-active-class":"animate__animated animate__fadeInDown","leave-active-class":"animate__animated animate__fadeOutUp","duration":300}},_vm._l((_vm.countNoteGeneral),function(index){return _c('b-card-body',{key:index},[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"border rounded p-2"},[_c('div',{staticClass:"d-flex flex-row-reverse py-50 px-25 col-sm-12"},[(_vm.arrayNoteGeneral.length > 1)?_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"size":"16","icon":"XIcon"},on:{"click":function($event){return _vm.removeItemDescription(index - 1)}}}):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Language","label-for":"product-add-title"}},[_c('validation-provider',{attrs:{"name":"Language","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"product-add-category","options":_vm.lanCodeOptions,"item-disabled":"disable","clearable":false,"selectable":function (option) {
                                return !_vm.arrayNoteGeneral.find(function (x) { return x.languageCode ? (x.languageCode.value === option.value) : null; })
                              }},model:{value:(_vm.arrayNoteGeneral[index - 1].languageCode),callback:function ($$v) {_vm.$set(_vm.arrayNoteGeneral[index - 1], "languageCode", $$v)},expression:"arrayNoteGeneral[index - 1].languageCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Pre Checkin Des"}},[_c('validation-provider',{attrs:{"name":"Pre Checkin Des","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('quill-editor',{staticClass:"editor-form",attrs:{"options":_vm.snowOption},model:{value:(_vm.arrayNoteGeneral[index - 1].preCheckinDes),callback:function ($$v) {_vm.$set(_vm.arrayNoteGeneral[index - 1], "preCheckinDes", $$v)},expression:"arrayNoteGeneral[index - 1].preCheckinDes"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Pre Checkin Privacy"}},[_c('validation-provider',{attrs:{"name":"Pre Checkin Privacy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('quill-editor',{staticClass:"editor-form",attrs:{"options":_vm.snowOption},model:{value:(_vm.arrayNoteGeneral[index - 1].preCheckinPrivacy),callback:function ($$v) {_vm.$set(_vm.arrayNoteGeneral[index - 1], "preCheckinPrivacy", $$v)},expression:"arrayNoteGeneral[index - 1].preCheckinPrivacy"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Checkin Hotel Note"}},[_c('validation-provider',{attrs:{"name":"Checkin Hotel Note","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('quill-editor',{staticClass:"editor-form",attrs:{"options":_vm.snowOption},model:{value:(_vm.arrayNoteGeneral[index - 1].checkinHotelNote),callback:function ($$v) {_vm.$set(_vm.arrayNoteGeneral[index - 1], "checkinHotelNote", $$v)},expression:"arrayNoteGeneral[index - 1].checkinHotelNote"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Checkout Hotel Note"}},[_c('validation-provider',{attrs:{"name":"Checkout Hotel Note","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('quill-editor',{staticClass:"editor-form",attrs:{"options":_vm.snowOption},model:{value:(_vm.arrayNoteGeneral[index - 1].checkoutHotelNote),callback:function ($$v) {_vm.$set(_vm.arrayNoteGeneral[index - 1], "checkoutHotelNote", $$v)},expression:"arrayNoteGeneral[index - 1].checkoutHotelNote"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Pre Checkout Hotel Note"}},[_c('validation-provider',{attrs:{"name":"Pre Checkout Hotel Note","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('quill-editor',{staticClass:"editor-form",attrs:{"options":_vm.snowOption},model:{value:(_vm.arrayNoteGeneral[index - 1].preCheckoutHotelNote),callback:function ($$v) {_vm.$set(_vm.arrayNoteGeneral[index - 1], "preCheckoutHotelNote", $$v)},expression:"arrayNoteGeneral[index - 1].preCheckoutHotelNote"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var ariaDescribedby = ref.ariaDescribedby;
return [_c('validation-provider',{attrs:{"name":"Default","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('b-form-radio',{attrs:{"aria-describedby":ariaDescribedby,"name":"some-radios","value":index - 1},model:{value:(_vm.numberDefault),callback:function ($$v) {_vm.numberDefault=$$v},expression:"numberDefault"}},[_vm._v(" Default language ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})]}}],null,true)})],1)])],1)}),1),_c('b-col',{staticClass:"pb-2",attrs:{"cols":"12"}},[(_vm.lanCodeOptions.length > _vm.arrayNoteGeneral.length)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"ml-1",attrs:{"size":"sm","variant":"primary"},on:{"click":_vm.addNewItemInItemFormDescription}},[_vm._v(" Add note general config in another language ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":_vm.updateGeneralConfig}},[_vm._v(" Update ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"to":{ name: 'general-config' },"variant":"outline-secondary"}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }