import { ref } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function updateGeneralConfig() {
  const toast = useToast()
  const title = ref('')
  const isActive = ref(false)
  const key = ref('')
  const cukcukCategories = ref([])
  const cukcukCategoryOptions = ref([])
  const cukcukBranch = ref('')
  const configUrl = ref('')
  const cukcukBranchOptions = ref([])
  const loading = ref(false)
  const arrayNoteGeneral = ref([
    {
      languageCode: null,
      preCheckinDes: '',
      preCheckinPrivacy: '',
      checkinHotelNote: '',
      checkoutHotelNote: '',
      preCheckoutHotelNote: '',
      isDefaultLanguage: false,

    },
  ])
  const numberDefault = ref(null)
  const countNoteGeneral = ref(1)
  const idGeneralConfig = ref(null)
  // Fetch post
  if (router.currentRoute.params.id) {
    idGeneralConfig.value = router.currentRoute.params.id
    loading.value = true
    store
      .dispatch('cukcuk/fetchCukCukBranch')
      .then(response => {
        const res = response.body.Data
        cukcukBranchOptions.value = res.map(x => ({
          label: x.Name,
          value: x.Id,
        }))
        const params = {
          includeInactive: false,
          includeOtherCategory: true,
        }
        store
          .dispatch('cukcuk/fetchCukCukCategory', params)
          .then(response2 => {
            const res2 = response2.body
            cukcukCategoryOptions.value = res2.map(x => ({
              label: x.Name,
              value: x.Id,
            }))
            store
              .dispatch('generalConfig/fetchGeneralConfig', { id: idGeneralConfig.value })
              .then(response3 => {
                const res3 = response3.body
                title.value = res3.title
                isActive.value = res3.isActive
                key.value = res3.key
                configUrl.value = res3.configUrl
                cukcukCategories.value = res3.cukcukCategories.map(x => ({
                  label: cukcukCategoryOptions.value.find(y => y.value === x)
                    ? cukcukCategoryOptions.value.find(y => y.value === x).label : 'None',
                  value: x,
                }))
                cukcukBranch.value = {
                  label: cukcukBranchOptions.value.find(x => x.value === res3.cukcukBranch)
                    ? cukcukBranchOptions.value.find(x => x.value === res3.cukcukBranch).label : 'None',
                  value: res3.cukcukBranch,
                }
                countNoteGeneral.value = res3.translations.length
                arrayNoteGeneral.value = res3.translations.map(x => ({
                  preCheckinDes: x.preCheckinDes,
                  languageCode: store.state.app.lanCodeOptions.find(e => e.value === x.languageCode),
                  preCheckinPrivacy: x.preCheckinPrivacy,
                  checkinHotelNote: x.checkinHotelNote,
                  checkoutHotelNote: x.checkoutHotelNote,
                  preCheckoutHotelNote: x.preCheckoutHotelNote,
                  availableCancel: x.availableCancel,
                  isDefaultLanguage: x.isDefaultLanguage,
                })).reverse()
                numberDefault.value = arrayNoteGeneral.value.findIndex(x => x.isDefaultLanguage)
                loading.value = false
              })
          })
          .catch(e => {
            console.log(e)
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error fetching cukcuk category list',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            loading.value = false

          })
      })
      .catch(e => {
        console.log(e)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching cukcuk branch list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        loading.value = false
      })
  }

  return {
    arrayNoteGeneral,
    countNoteGeneral,
    isActive,
    title,
    key,
    cukcukCategories,
    cukcukBranch,
    cukcukCategoryOptions,
    cukcukBranchOptions,
    configUrl,
    loading,
    numberDefault,
  }
}
